/* Labels
/* ------------------------- */

.label {
	font-weight: bold;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
	padding: .5em 1.1em;
	font-size: 8px;
	@include fix_small_chars();
	font-family: $font-family-accent;
	color: $label-color;
	text-transform: uppercase;
}

// Add hover effects, but only for links
a.label {
	&:hover,
	&:focus {
		color: $label-link-hover-color;
	}
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
	@include label-variant($label-default-bg);
}

.label-primary {
	@include label-variant($label-primary-bg);
}

.label-success {
	@include label-variant($label-success-bg);
}

.label-info {
	@include label-variant($label-info-bg);
}

.label-warning {
	@include label-variant($label-warning-bg);
}

.label-danger {
	@include label-variant($label-danger-bg);
}
