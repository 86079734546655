/* Ratings */
.ratings {

  .fa {
    color: $product-rating-color;
  }

  .empty {
    color: $product-rating-color-disabled;
  }
}
