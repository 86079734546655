/* Video Playlist */
.alc-video-player-col {
	padding-right: 0;
}
.alc-video-playlist-col {

	@media (min-width: 992px) {
		border-left: 1px solid $card-border-color;
		padding-left: 0;
	}
}

// Title
.alc-video-player__header {
	border-bottom: 1px solid $card-border-color;
	padding-left: $card-horizontal-padding;
	padding-right: $card-horizontal-padding;
	padding-top: 13px;
	padding-bottom: 13px;
	position: relative;

	@media (max-width: 991px) {
		border-top: 1px solid $card-border-color;
	}

	.slick-prev,
	.slick-next {
		top: 50%;
		transform: translateY(-50%);
	}
}
	.alc-video-player__title {
		font-size: 11px;
		line-height: 1.2em;
		margin-bottom: 0;
	}

// Video Player
.alc-video-player {

	.posts__thumb--video {
		float: none;
		margin: 0;

		img {
			width: 100%;
		}
	}
}

// Playlist
.alc-video-player__video-list {
	position: relative;

	.posts__item {
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 0 !important;
		padding-top: 18px;
		padding-bottom: 18px;

		&:hover {
			cursor: pointer;
		}

		.posts__title {

			@media (min-width: 992px) and (max-width: 1199px) {
				font-size: 12px;
			}
		}
	}
}

// Embeded Player
.alc-embeded-player {
	position: relative;
	overflow: hidden;
	background-color: #000;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	border: 1px solid $card-border-color;

	.card--no-paddings & {
		border-bottom-left-radius: $card-border-radius;
		border-bottom-right-radius: $card-border-radius;
		border: none;
	}
}
	.alc-embeded-player__overlay {
		display: flex;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.8);
		justify-content: center;
		align-items: center;

		&:hover {
			cursor: pointer;

			.alc-embeded-player__icon {
				opacity: .5;
			}
		}
	}
		.alc-embeded-player__icon {
			display: block;
			color: $color-white;
			font-size: 30px;
			line-height: 1em;
			transition: opacity .3s ease;
		}
